import React, {FC} from 'react';
import {provider, useInstance} from 'react-ioc';
import {BrowserRouter as Router, Navigate, Route, Routes,} from 'react-router-dom';
import {YMInitializer} from 'react-yandex-metrika';
import {ApolloClient, ApolloProvider} from '@apollo/client';
import {ConfigProvider, Skeleton} from 'antd';
import ru_RU from 'antd/locale/ru_RU';
import {observer} from 'mobx-react-lite';

import 'dayjs/locale/ru';

import { Me } from '../components/common/Me';
import { Metrika } from '../components/common/Metrika';
import ScrollToTop from '../components/common/ScrollToTop';
import Layout from '../components/layout/Layout';
import {PaymentPage} from '../components/Payment/PaymentPage';
import {
    INDEX, LOGIN, OFFERS, PAYMENT, PAYMENT_UPDATE, REGISTRATION,
    ROOT,
} from '../config/routes';
import ApolloClientDI from '../model/graphql/ApolloClientDI';
import {Store} from '../model/store/Store';
import StoreDI from '../model/store/StoreDI';
import {Index} from '../pages/index/Index';
import {Offers} from '../pages/index/Offers';
import {Login} from '../pages/Login/Login';
import {OrderUpdate} from '../pages/Order/OrderUpdate';
import {Registration} from '../pages/registration/Registration';

import './App.scss';
const App: FC = () => {

    const apolloClient = useInstance(ApolloClient);
    const {hydrated, auth} = useInstance(Store)

    if (!hydrated) {
        return <Skeleton active/>
    }

    return (
        <ConfigProvider locale={ru_RU} theme={{
            token: {
                colorPrimary: '#5BCC81',
                borderRadius: 8,
                fontFamily: '\'Montserrat\', sans-serif',
                // fontSize: 16,
            },
        }}>
            <ApolloProvider client={apolloClient}>
                <Router>
                    <ScrollToTop/>
                    <YMInitializer accounts={[97334247]}/>
                    <Metrika/>
                    {auth.token !== null && <Me/>}

                    <Routes>
                        <Route path={ROOT} element={<Layout/>}>
                            <Route path={INDEX} element={<Index/>}/>
                            <Route path={REGISTRATION} element={<Registration/>}/>

                            {auth.token === null &&
                                <>
                                    <Route path={LOGIN} element={<Login/>}/>
                                </>
                            }
                            <Route path={OFFERS} element={<Offers/>}/>
                            {auth.token !== null  &&
                                <>
                                    <Route path={PAYMENT} element={<PaymentPage/>}/>
                                    <Route path={PAYMENT_UPDATE} element={<OrderUpdate/>}/>
                                    <Route path={LOGIN} element={<Navigate to={INDEX}/>}/>
                                </>
                            }
                            <Route path="*" element={<Navigate to={INDEX}/>}/>
                        </Route>
                    </Routes>
                </Router>
            </ApolloProvider>
        </ConfigProvider>
);
};

export default provider(
StoreDI(),
ApolloClientDI(),
)(observer(App));
