import { useState } from 'react';
import {useInstance} from 'react-ioc';
import {Link, useNavigate} from 'react-router-dom';
import {HomeOutlined, RightOutlined} from '@ant-design/icons';
import {useMutation} from '@apollo/client';
import {Button, Checkbox} from 'antd';
import {observer} from 'mobx-react-lite';

import {DOC_OFFER, INDEX} from '../../config/routes';
import {getSession} from '../../hooks/getSession';
import {Store} from '../../model/store/Store';
import {
    createGetMoneyKzOrder,
    createGetMoneyKzOrderVariables
} from '../../shared/graphql/__generated__/createGetMoneyKzOrder';
import {CREATE_ORDER} from '../../shared/graphql/createGetMoneyKzOrder';

import cls from './Payment.module.scss'

export const Payment = observer(() => {

    const [check, setCheck] = useState<boolean>(true);

    const navigate = useNavigate()
    const {auth} = useInstance(Store)
    const [createOrder, {loading}] = useMutation<createGetMoneyKzOrder,createGetMoneyKzOrderVariables>(CREATE_ORDER)

    const onCreateOrder = async () => {
        await createOrder({
            variables: {
                session: getSession(),
                token: auth.token!,
                data: {},
            },
            onCompleted: (data) => {
                const paymentURL = data.createGetMoneyKzOrder?.data?.attributes?.paymentURL!
                window.location.assign(paymentURL);
            }
        })
    }

    return (
        <div className={cls.wrapper}>
            <h2>Продолжайте заполнение анкеты и добавьте свою банковскую карту.</h2>
            <div className={cls.row}>
                <div>
                    <div className={'bold'}>Услуга «Телеюрист»</div>
                    <div className={cls.checkbox}>
                        <Checkbox checked={check} onChange={() => setCheck(!check)}>
                            Согласен на присоединение к <Link to={DOC_OFFER}>Оферте</Link>
                        </Checkbox>
                    </div>

                </div>
                <div className={cls.sum}>
                    1 750 ₸
                </div>
            </div>
            <div className={cls.control}>
                <Button size={'large'} icon={<HomeOutlined/>} onClick={() => navigate(INDEX)}/>
                <Button type={'primary'} size={'large'} icon={<RightOutlined/>} onClick={onCreateOrder} loading={loading} disabled={!check}>Продолжить</Button>
            </div>
        </div>
    )
})